<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogCambiarEgreso.estatus"
    max-width="600"
  >
    <v-card>
    	<v-card-title primary-title>
    	  Solicitud de cambio de egreso
    	</v-card-title>
    	<v-card-text v-if="!egreso_modificar.idcambios_egresos">
    		<v-radio-group
		      v-model="tipo_reporte"
		      row
		    >
		      <v-radio
		        label="Cambio de ciclo"
		        :value="1"
		      ></v-radio>
		      <v-radio
		        label="Eliminar"
		        :value="2"
		      ></v-radio>
		      <v-radio
		        label="Concepto"
		        :value="3"
		      ></v-radio>
		      <v-radio
		        label="Monto"
		        :value="4"
		      ></v-radio>
		    </v-radio-group>
    		
        <v-autocomplete
    			v-if="tipo_reporte == 1 "
          filled
          dense
          clearable
          v-model="nuevo_ciclo"
          :items="ciclos"
          label="Nuevo ciclo"
          persistent-hint
          item-text="ciclo"
          item-value="id_ciclo"
        >
        </v-autocomplete>

    		<v-textarea
    			v-if="tipo_reporte == 3"
        	v-model="nuevo_concepto"
        	filled
        	dense
        	label="Nuevo concepto"
        	rows="3"
        	auto-grow
        >	
        </v-textarea>

        <span v-if="tipo_reporte == 4"><b>Monto actual: {{  egreso_modificar.costo_total }} </b></span>

        <v-text-field
    			v-if="tipo_reporte == 4"
        	v-model="nuevo_monto"
        	filled
        	dense
        	label="Nuevo monto"
        	type="number"
        >	
        </v-text-field>


        <v-textarea
        	v-model="comentarios"
        	filled
        	dense
        	label="Motivo"
        	rows="3"
        	auto-grow
        >	
        </v-textarea>
    	</v-card-text>

    	<v-card-text v-if="egreso_modificar.idcambios_egresos">
    		<v-radio-group
		      v-model="tipo_reporte"
		      row
		      readonly
		    >
		      <v-radio
		        label="Cambio de ciclo"
		        :value="1"
		      ></v-radio>
		      <v-radio
		        label="Eliminar"
		        :value="2"
		      ></v-radio>
		      <v-radio
		        label="Concepto"
		        :value="3"
		      ></v-radio>
		      <v-radio
		        label="Monto"
		        :value="4"
		      ></v-radio>
		    </v-radio-group>
    		
        <v-autocomplete
    			v-if="tipo_reporte == 1 "
          filled
          dense
          clearable
          v-model="nuevo_ciclo"
          :items="ciclos"
          label="Nuevo ciclo"
          persistent-hint
          item-text="ciclo"
          item-value="id_ciclo"
          readonly
        >
        </v-autocomplete>

    		<v-textarea
    			v-if="tipo_reporte == 3"
        	v-model="nuevo_concepto"
        	filled
        	dense
        	label="Nuevo concepto"
        	rows="3"
        	auto-grow
        	readonly
        >	
        </v-textarea>

        <span v-if="tipo_reporte == 4"><b>Monto anterior: {{  egreso_modificar.costo_total }} </b></span>

        <v-text-field
    			v-if="tipo_reporte == 4"
        	v-model="nuevo_monto"
        	filled
        	dense
        	label="Nuevo monto"
        	type="number"
        	readonly
        >	
        </v-text-field>


        <v-textarea
        	v-model="comentarios"
        	filled
        	dense
        	label="Motivo"
        	rows="3"
        	auto-grow
          readonly
        >	
        </v-textarea>
    	</v-card-text>

    	<v-card-actions v-if="!egreso_modificar.idcambios_egresos">
		    <v-btn 
		    	dark 
		    	tile 
		    	@click="dialogCambiarEgreso.estatus = false" 
		    	color="black"
		    	small
		    >
		    	<v-icon left small>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
		  	<v-spacer></v-spacer>
		    <v-btn 
		    	dark 
		    	tile 
		    	@click="agregar()" 
		    	color="green"
		    	small
		    >
		    	<v-icon left small>mdi-content-save</v-icon>
		  		Guardar
		  	</v-btn>
    	</v-card-actions>

    	<v-card-actions v-if="egreso_modificar.idcambios_egresos">
		    <v-btn 
		    	small
		    	dark 
		    	tile 
		    	@click="rechazar()" 
		    	color="black"
		    >
		    	<v-icon left small>mdi-close</v-icon>
		  		Rechazar
		  	</v-btn>
		  	<v-spacer></v-spacer>
		    <v-btn 
		    	small
		    	dark 
		    	tile 
		    	@click="save()" 
		    	color="green"
		    >
		    	<v-icon left small>mdi-content-save</v-icon>
		  		Aceptar
		  	</v-btn>
    	</v-card-actions>

    	<v-btn 
    		icon
    		absolute
    		top
    		right
    		@click="dialogCambiarEgreso.estatus = false"
    	>
    		<v-icon>mdi-close</v-icon>
    	</v-btn>

    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-dialog>
</template>


<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'


	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'


	export default {
		props:[
			'dialogCambiarEgreso',
			'egreso_modificar',
			'ciclos'
	  ],

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      tipo_reporte: null,
      ciclo: null,
      comentarios:'',
      ciclo_anterior: null,
			nuevo_ciclo: null,
			nuevo_monto:0,

      editedItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                '', 
		    cantidad:                0, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      defaultItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                '', 
		    cantidad:                0, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      detalles:[],
      empleados:[],
      elementos:[],
      planteles:[],
      verTabla: true,

      id_usuario_solicita:'',
      id_sucursal_solicita:'',
      id_requisicion_compra_estatus:'',
      id_usuario_ultimo_cambio:'',

      id_usuario_compra: 28,
      tipo_requisicion:1,
      id_ciclo:0,
      comentarios:'',
      idrequisicion_compra : null
    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
    },

    watch: {
      
    },

    async created () {
    	console.log( this.egreso_modificar )
    	if( this.egreso_modificar.nuevo_ciclo ){ this.tipo_reporte = 1}
    	if( this.egreso_modificar.eliminar ){ this.tipo_reporte = 2}
    	if( this.egreso_modificar.nuevo_concepto ){ this.tipo_reporte = 3}
    	if( this.egreso_modificar.nuevo_monto ){ this.tipo_reporte = 4}
    	
    	this.nuevo_ciclo    = this.egreso_modificar.nuevo_ciclo
    	this.nuevo_concepto = this.egreso_modificar.nuevo_concepto
    	this.comentarios    = this.egreso_modificar.notas
    	this.nuevo_monto    = this.egreso_modificar.nuevo_monto

    },

    methods: {
      agregar(){

      	const payload = {
      		idpartida        : this.egreso_modificar.id,
      		idsolicito       : this.getdatosUsuario.iderp,
      		idautorizo       : null,
      		eliminar         : this.tipo_reporte == 2 ? 1 : 0,
      		cambio_ciclo     : this.tipo_reporte == 1 ? 1 : 0,
      		ciclo_anterior   : this.tipo_reporte == 1 ? this.egreso_modificar.id_ciclo : null,
      		nuevo_ciclo      : this.tipo_reporte == 1 ? this.nuevo_ciclo    : null,
      		notas            : this.comentarios,
      		concepto         : this.nuevo_concepto,
      		concepto_anterior: this.egreso_modificar.concepto,
      		monto_anterior   : this.egreso_modificar.costo_total,
      		nuevo_monto      : this.tipo_reporte == 4 ? this.nuevo_monto : null,
      	}

      	this.cargar = true
        
        return this.$http.post('solicitar.cambio.egreso', payload ).then(response=>{
        	this.cargar      = false
        	this.$emit('initialize')
        	this.dialogCambiarEgreso.estatus = false
	      	// Agregarmos primero los datos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      rechazar(){
      	this.cargar = true
        this.egreso_modificar.idautorizo = this.getdatosUsuario.iderp

        return this.$http.post('procesar.cambio.rechazado', this.egreso_modificar ).then(response=>{
        	this.cargar      = false
        	this.$emit('initialize')
        	this.dialogCambiarEgreso.estatus = false
	      	// Agregarmos primero los datos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save ( ){
      	this.cargar = true
        this.egreso_modificar.idautorizo = this.getdatosUsuario.iderp

        return this.$http.post('procesar.cambio.egreso', this.egreso_modificar ).then(response=>{
        	this.cargar      = false
        	this.$emit('initialize')
        	this.dialogCambiarEgreso.estatus = false
	      	// Agregarmos primero los datos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>