<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Panel Control de Cuentas
            <v-spacer></v-spacer>

            <!-- Boton para descargar el excel -->
            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(filterNomina, 'Egresos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- Administrar cuentas -->
            <v-btn
              class="mr-2"
              small
              dark
              color="orange"
              @click="dialogAdminCuentas.estatus = true"
              tile
            >
              <v-icon small>mdi-plus</v-icon>
              Admin. Cuentas
            </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>

          </v-card-title>

          <v-card-text>
            <v-row>

            	<v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona cuenta</label>
                <v-select
                  label="Cuenta"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="cuenta"
                  :items="cuentas"
                  item-text="cuenta_completa"
                  item-value="idcuentas_escuela"
                  clearable
                ></v-select>
              </v-col> 

              <v-col cols="12" md="4" lg="2" class="pb-0">
                <label>Selecciona un ciclo</label>
                <v-autocomplete
                  label="Ciclo"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  v-model="ciclo"
                  :items="ciclos"
                  item-text="ciclo"
                  item-value="ciclo"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <label>Fecha inicio</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha_inicio"
                  dense
                  clearable
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label>Fecha final</label>
                <v-text-field
                  filled
                  single-line
                  label="Fecha final"
                  type="date"
                  hide-details
                  v-model="fecha_final"
                  dense
                  clearable
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <br />

            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8">
                	<b>TOTAL EN CUENTA:</b> {{ formatoMoneda( totalEnCuenta ) }}
                </span>
              </v-col>
            </v-row>
            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8">
                	<b>TOTAL GASTADO:</b> {{ formatoMoneda( totalGastado ) }}
                </span>
              </v-col>
            </v-row>
            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8">
                	<b>QUEDAN:</b> {{ formatoMoneda( totalEnCuenta - totalGastado ) }} 
                </span>
              </v-col>
            </v-row>
            
            <v-row align="center" class="no-gutters" justify="space-between">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="filterNomina"
              dense
              class="elevation-0 mt-4"
              :search="buscar"
              item-class="clase_row"
            >
              <template v-slot:item.estatus="{ item }">
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.estatus == 'Sin Estatus'"
                  >Sin Estatus</v-chip
                >
                <v-chip
                  color="red"
                  small
                  dark
                  v-if="item.estatus == 'Sin Comprobante'"
                  >Sin Comprobante</v-chip
                >
                <v-chip
                  color="orange"
                  small
                  dark
                  v-if="item.estatus == 'Con Comprobante'"
                  >Con Comprobante</v-chip
                >
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.estatus == 'Autorizado'"
                  >Autorizado</v-chip
                >
                <v-chip
                  color="black"
                  small
                  dark
                  v-if="item.estatus == 'Rechazado'"
                  >Rechazado</v-chip
                >
              </template>

              <template v-slot:item.imagen_pago="{ item }">
                <span v-for="(foto, i) in item.fotosPago" :key="i">
                  <v-icon
                    color="primary"
                    small
                    @click="
                      (idfoto = item),
                        verImagen(foto),
                        getMensajesRechazo(),
                        getComentarioComprobante()
                    "
                    v-if="extensionImagenes.includes(foto.extension)"
                  >
                    mdi-file-image
                  </v-icon>
                </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  color="blue"
                  @click="(idtabla = item), (dialogSubirComprobante = true)"
                >
                  mdi-apple-keyboard-caps
                </v-icon>
              </template>

              <template v-slot:item.solicitar_cambio="{ item }">
                <v-icon
                  v-if="!item.idcambios_egresos"
                  small
                  class="mr-2"
                  color="black"
                  @click="egreso_modificar = item, dialogCambiarEgreso.estatus = true"
                >
                  mdi-pencil
                </v-icon>

                <v-icon
                  v-if="item.idcambios_egresos && [1,334].includes( getdatosUsuario.iderp ) && !item.estatusSolicitud"
                  small
                  class="mr-2"
                  color="orange"
                  @click="egreso_modificar = item, dialogCambiarEgreso.estatus = true"
                >
                  mdi-eye
                </v-icon>
              </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AdminCuentas  
      :dialogAdminCuentas="dialogAdminCuentas" 
      v-if="dialogAdminCuentas.estatus"
      @getCuentas="getCuentas"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { Clipboard } from "@capacitor/clipboard";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import EditarEgreso    from "@/components/egresos/EditarEgrego"
import AdminCuentas    from "@/components/egresos/AdminCuentas"

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  components: {
    Alerta,
    carga,
    EditarEgreso,
    AdminCuentas
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    historialEgreso:[],

    dialogAdminCuentas:{
    	estatus: false,
    },

    dialog: false,
    dialogDelete: false,
    dialogVer: false,
    dialog_mensaje_rechazo: false,
    dialog_mensaje_rechazo2: false,
    dialog_mensaje_rechazo_multiple: false,
    dialogComprobantesMultiples: false,
    indexOf: [],
    proyectos: [],
    id_proyecto: 0,

    inversion: null,
    partidasFinales:[],

    egreso_modificar: null,
    dialogCambiarEgreso:{
      estatus: false,
    },
    dialogPapeleraEgresos:{
      estatus: false
    },

    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Id Requisición", value: "id_requisicion_compra" },
      { text: "Fecha", value: "fecha_partida" },
      { text: "Ciclo", value: "ciclo" },
      { text: "Elemento", value: "elemento_mantenimiento" },
      { text: "Concepto", value: "concepto" },
      { text: "Plantel", value: "plantel" },
      { text: "Proyecto", value: "proyecto" },
      { text: "Cuenta/Local", value: "numero_cuenta" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unit.", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Metodo de pago", value: "tipo_pago" },
    ],

    headers4: [
      { text: "ID"              , value: "id", sortable: false },
      { text: "Solicitó"        , value: "nombre_completo" },
      { text: "Autorizó/Rechazó", value: "nombreAutorizo" },
      { text: "Concepto"        , value: "concepto" },
      { text: "Proyecto"        , value: "proyecto" },
      { text: "Cantidad"        , value: "cantidad" },
      { text: "Costo Unit."     , value: "costo_unitario" },
      { text: "Total"           , value: "costo_total" },
      { text: "Tipo Actualización"         , value: "tipoActualizacion" },
    ],

    headers2: [
      { text: "Concepto", value: "concepto", sortable: false },
      { text: "Ciclo", value: "ciclo" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unitario", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Estatus", value: "estatus" },
    ],

    headers3: [
      { text: "SUELDO 1", value: "sueldo_1" },
      { text: "SUELDO2", value: "sueldo_2" },
      { text: "COMISIONES", value: "comisiones" },
      { text: "CAJA DE AHORRO", value: "caja_de_ahorro" },
      { text: "A DEPOSITAR", value: "a_depositar" },
      { text: "DEP 1", value: "dep_1" },
      { text: "DEP 2", value: "dep_2" },
      { text: "Deuda", value: "deuda" },
    ],

    headersCiclosEgresos: [
        { text: 'ID'     , value: 'id_ciclo'     },
        { text: 'Ciclo'    , value: 'ciclo'       },
        { text: '# Egresos'    , value: 'Numero_Egresos'       },
        { text: 'Con Comprobante'    , value: 'Con_Comprobante'       },
        { text: 'Sin Comprobante'    , value: 'Sin_Comprobante'       },
        { text: 'Rechazados'    , value: 'Rechazado'       },
        { text: 'Autorizados'    , value: 'Autorizado'       },
        { text: 'Total'    , value: 'Total'       },

      ],

    partidasSelected: [],
    partidasSelected2: [],

    nomina: [],
    opVisualizacion: 1,

    editedIndex: -1,

    editedItem: {
      id: "",
      nombre_completo: "",
      detalles: [],
      mensaje: "",
    },

    item: {
      respuesta: "",
      respuestas: "",
    },

    defaultItem: {
      idsolicitud_nomina: "",
      id_usuario: 0,
      idareas_ticket: 0,
    },

    itemComprobante: {
      id_partida: "",
      sueldo_1: "",
      sueldo_2: "",
      comisiones: "",
      caja_de_ahorro: "",
      a_depositar: "",
      dep_1: "",
      dep_2: "",
      deuda: "",
    },

    itemComprobanteDefault: {
      id_partida: "",
      sueldo_1: "",
      sueldo_2: "",
      comisiones: "",
      caja_de_ahorro: "",
      a_depositar: "",
      dep_1: "",
      dep_2: "",
      deuda: "",
    },

    file: "",
    dragging: false,
    vistaPrevia: null,
    autorizar: null,
    nota_rechazo: "",
    url: "",
    idtabla: [],
    idfoto: [],
    vueltas: 0,
    img: null,
    mensajes: [],
    respuestas: [],
    value: 0,
    result: [],
    porcentajeTotal: 100,
    cd1: 0,
    cd2: 0,
    cd3: 0,
    cd4: 0,
    cdt: 0,

    fecha_inicio: null,
    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    buscar: "",

    dialogNuevaNomina: {
      estatus: false,
    },

    dialogSubirComprobante: false,
    dialogSubirComprobante2: false,
    dialogCiclosEgresos: false,
    archivos: null,
    dialogImagen: false,
    url_servidor: "",
    imagen: "",
    extensionImagenes: ["BMP", "GIF", "jpg", "jpeg", "png", "webp","pdf"],
    tipo: "Rechazado",
    tipo_pago: 0,
    dialogVer: false,
    fullscreen: false,
    img: null,
    comentarios: [],
    columnsVisible: true,

    ciclos: [],
    ciclo: null,
    estatus: null,
    proyecto: null,
    ciclosegresos: null,
    escuela: 1,
    visibleHeaders: [
      { text: "ID", value: "id", sortable: false },
      { text: "Id Requisición", value: "id_requisicion_compra" },
      { text: "Ciclo", value: "ciclo" },
      { text: "Elemento", value: "elemento_mantenimiento" },
      { text: "Concepto", value: "concepto" },
      { text: "Plantel", value: "plantel" },
      { text: "Proyecto", value: "proyecto" },
      { text: "Cuenta/Local", value: "numero_cuenta" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unit.", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Metodo de pago", value: "tipo_pago" },
      { text: "Estatus", value: "estatus" },
      { text: "Historial", value: "historial" },
      { text: "Comprobante", value: "imagen_pago" },
      { text: "Cambio", value: "solicitar_cambio", sortable: false },
      { text: "Subir Comprobante", value: "actions", sortable: false },
    ],
    cuentas:[],
    cuenta: null
    
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    totalEnCuenta( ){
    	if( this.cuenta ){
    		const monto = this.cuentas.find( el => el.idcuentas_escuela == this.cuenta )
    		return monto.monto;
    	}else{
    		return 0
    	}
    },

    totalGastado( ){
      let subtotal2 = this.nomina
        .map((item2) => item2.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return subtotal2;
    },

    filterNomina() {
      let data = this.nomina;

      if( this.inversion ){
        data = data.filter( el => { return el.ciclo ? el.ciclo.match('INVERS') : false })
      }

      if (this.ciclo) {
        data = data.filter((el) => el.ciclo == this.ciclo);
      }

      if (this.estatus) {
        data = data.filter((el) => el.estatus == this.estatus);
      }

      if (this.proyecto) {
        data = data.filter((el) => el.proyecto == this.proyecto);
      }

      return data;
    },

    filterCiclosEgresos() {
      let data = this.ciclosegresos;

      if (this.escuela) {
        data = data.filter((el) => el.escuela == this.escuela);
      }
      return data;
    },

    total() {
      let subtotal = this.nomina
        .map((item) => item.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },

    cantidadTotal() {
      let subtotal = this.nomina.length;
      this.cdt = subtotal;
      return Math.floor(subtotal);
    },

    totalPartidasMultiples() {
      let subtotal = this.result
        .map((item) => item.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal);
    },

    cantidadPartidasMultiples() {
      let subtotal = this.result.length;
      return Math.floor(subtotal);
    },

    totalComprobante() {
      this.nomina1 = this.nomina.filter(
        (objeto1) => objeto1.estatus == "Con Comprobante"
      );
      let subtotal1 = this.nomina1
        .map((item1) => item1.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal1);
    },

    cantidadComprobante() {
      this.nomina1 = this.nomina.filter(
        (objeto1) => objeto1.estatus == "Con Comprobante"
      );
      let subtotal1 = this.nomina1.length;
      this.cd1 = subtotal1;
      return Math.floor(subtotal1);
    },

    porcentajeComprobante() {
      let subtotal1 = (this.cd1 / this.cdt) * 100;
      const resultadoRedondeado = subtotal1.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalSinComprobante() {
      this.nomina2 = this.nomina.filter(
        (objeto2) => objeto2.estatus == "Sin Comprobante"
      );
      let subtotal2 = this.nomina2
        .map((item2) => item2.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal2);
    },

    cantidadSinComprobante() {
      this.nomina2 = this.nomina.filter(
        (objeto2) => objeto2.estatus == "Sin Comprobante"
      );
      let subtotal2 = this.nomina2.length;
      this.cd2 = subtotal2;
      return Math.floor(subtotal2);
    },

    porcentajeSinComprobante() {
      let subtotal2 = (this.cd2 / this.cdt) * 100;
      const resultadoRedondeado = subtotal2.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalRechazado() {
      this.nomina4 = this.nomina.filter(
        (objeto4) => objeto4.estatus == "Rechazado"
      );
      let subtotal4 = this.nomina4
        .map((item4) => item4.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal4);
    },

    cantidadRechazado() {
      this.nomina4 = this.nomina.filter(
        (objeto4) => objeto4.estatus == "Rechazado"
      );
      let subtotal4 = this.nomina4.length;
      this.cd3 = subtotal4;
      return Math.floor(subtotal4);
    },

    porcentajeRechazado() {
      let subtotal4 = (this.cd3 / this.cdt) * 100;
      const resultadoRedondeado = subtotal4.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },

    totalAutorizado() {
      this.nomina3 = this.nomina.filter(
        (objeto3) => objeto3.estatus == "Autorizado"
      );
      let subtotal3 = this.nomina3
        .map((item3) => item3.costo_total)
        .reduce((prev, curr) => prev + curr, 0);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(subtotal3);
    },

    cantidadAutorizado() {
      this.nomina3 = this.nomina.filter(
        (objeto3) => objeto3.estatus == "Autorizado"
      );
      let subtotal3 = this.nomina3.length;
      this.cd4 = subtotal3;
      return Math.floor(subtotal3);
    },

    porcentajeAutorizado() {
      let subtotal3 = (this.cd4 / this.cdt) * 100;
      const resultadoRedondeado = subtotal3.toFixed(2);
      return parseFloat(resultadoRedondeado);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    partidasSelected( newSelection, oldSelection ){
      // Comparar los arreglos para encontrar elementos agregados o eliminados
      const addedItems = newSelection.filter(item => !oldSelection.includes(item));
      const removedItems = oldSelection.filter(item => !newSelection.includes(item));

      if (addedItems.length > 0) {
        console.log('Elementos agregados:', addedItems);
        this.partidasFinales.push( addedItems[0].id )
      }

      if (removedItems.length > 0) {
        console.log('Elementos eliminados:', removedItems);
        this.partidasFinales = this.partidasFinales.filter( el => { return el.id != removedItems[0].id })
      }

      for( const i in this.partidasFinales ){
        console.log( this.partidasFinales[i] )
      }

    }
  },

  async created() {
    this.getCiclos();
    this.getCuentas()
    await this.getProyectos();
  },

  methods: {
    initialize() {
      this.nomina = [];
      this.cargar = true;

      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
        ciclo: this.ciclo,
        cuenta: this.cuenta
      };

      return this.$http
        .post("consultar.partidas.egreso.cuenta", payload)
        .then((response) => {
          this.nomina = response.data;
          this.cargar = false;

          if (
            this.getdatosUsuario.iderp == 1 ||
            this.getdatosUsuario.iderp == 623
          ) {
            if (this.vueltas == 0) {
              this.headers = this.headers.slice(0, -1);
              this.vueltas = 1;
            }
          }

          // if (this.getdatosUsuario.iderp == 1 || this.getdatosUsuario.iderp == 623){
          //  this.nomina = this.nomina.filter(function(prueba) {return prueba.estatus == 'Autorizado' || prueba.estatus === 'Con Comprobante' ;});
          // }

          // Validar si ya hay información, para poder subir el comprobante o mostrarlos
          if (this.editedIndex > -1) {
            const header = this.nomina.find(
              (el) => el.id == this.editedItem.id
            );

            this.editedItem = nomina;
          }
          if (this.dialogComprobantesMultiples) {
            const result = this.nomina.filter((el) => {
              return el.nombre_foto === this.idfoto.nombre_foto;
            });

            if (result.length > 1) {
              this.dialogComprobantesMultiples = true;
              this.result = result;
            } else {
              this.dialogImagen = true;
              this.dialogComprobantesMultiples = false;
            }
          }
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    formatoMoneda( value ){
    	if( value ){
    		return new Intl.NumberFormat("en-US", {
	        style: "currency",
	        currency: "USD",
	      }).format(value)
    	}else{
    		return 0
    	}
    },

    getCuentas( ){
    	this.dialogAdminCuentas.estatus = false
    	this.cargar = true
      this.cuentas = []
      return this.$http.get('admin.cuentas.list').then(response=>{
      	this.cuentas = response.data
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    getCiclos() {
      this.cargar = true;
      this.ciclos = [];
      return this.$http
        .get("ciclos.activos.erp")
        .then((response) => {
          this.ciclos = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.nomina.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.autorizar = null;
      this.nota_rechazo = "";
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getProyectos() {
      this.proyectos = [];
      return this.$http
        .get("consultar.proyectos")
        .then((response) => {
          this.proyectos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    getProyectos() {
      this.ciclosegresos = [];
      return this.$http
        .get("consultar.ciclos.egresos")
        .then((response) => {
          this.ciclosegresos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

  },
};
</script>
